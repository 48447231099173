<template>
    <div class="init">
        <el-form :model="fromValue" label-width="auto" ref='form_' class="fromClass"
            :rules='rules' label-position='top'>
            <el-form-item :label="$t('contra.text5')" prop="chain_protocol">
                <el-cascader v-model="fromValue.chain_protocol" :options="chain_protocolArray" @change="handleChange"/>
            </el-form-item>
             <el-form-item label="合约地址" prop="contractAddress">
                <el-input v-model="fromValue.contractAddress" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name20')" prop="collectionName">
                <el-input v-model="fromValue.collectionName" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name21')" prop="collectionSymbol">
                <el-input v-model="fromValue.collectionSymbol" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item label="Gas Limit" prop="gasLimit">
                <el-input v-model="fromValue.gasLimit" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name2')" prop="description">
                <el-input v-model="fromValue.description" :placeholder="$t('inputTit.shuru')" type='textarea'/>
            </el-form-item>
             <el-form-item :label="$t('nfts.name15')" prop="collectionImg">
                <el-upload class="avatar-uploader"
                    :show-file-list="false" :auto-upload='false'
                    :on-change="beforeAvatarUpload">
                    <img v-if="fromValue.collectionImg" :src="fromValue.collectionImg" class="avatar" />
                    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
                </el-upload>
                        <div class="lineWrite">
                            {{$t('nfts.relus1')}}1400px * 350px<br />
                            {{$t('nfts.relus2')}}<br />
                            {{$t('nfts.relus3')}}PNG、JPG、JPEG<br />
                        </div>
                <!-- <el-input v-model="fromValue.collectionImg" :placeholder="$t('inputTit.shuru')"/> -->
            </el-form-item>
             <el-form-item :label="$t('currencySetNft.name29')" prop="googleCode">
                <el-input v-model="fromValue.googleCode" :placeholder="$t('inputTit.shuru')"/>
            </el-form-item>
            
        </el-form>
    </div>
</template>
<script setup>
    import { ref,reactive,defineEmits,getCurrentInstance,defineExpose } from 'vue'
    import Api_ from '@/api/index'
    import { ElNotification } from 'element-plus'
    import addRules from './addRules'
    import { Plus } from '@element-plus/icons-vue'
    const { $t } = getCurrentInstance().proxy;
    import { randomCoding,dicFormatter } from '@/utils/util.js'
    
    const fromValue = reactive({ 
        createFrom:2,chain_protocol:'',gasLimit:'',
        contractAddress:'',chain:'',protocol:'',
        collectionName:'',description:'',collectionImg:'',googleCode:'',
        appId:0,collectionTemplateId:0,contractClassName:'0000',creator:'0000'
    })
    const chain_protocolArray = ref([])

    
    // fromValue
    const emit = defineEmits(['successFun'])
    const form_ = ref(null)
    const rules = ref(addRules.mainRules1);

    const handleChange = (e)=>{
        fromValue.chain = e[0]
        fromValue.protocol = e[1]
    }
    const changeProtocolType = ()=>{//
        Api_.getCoinOrxie({
            protocolType: 2,
            status: 1
        }).then(res=>{
            chain_protocolArray.value = dicFormatter(res.data)
        })
        fromValue.chain = ''
        fromValue.protocol = ''
    }
    changeProtocolType()
    const completeFun = ()=>{//点击完成
        if (!form_.value) return
        form_.value.validate((e) => {
            if(e){
                let obj_ = JSON.parse(JSON.stringify(fromValue))
                obj_.transactionId = randomCoding(64)
                obj_.mintGasLimit = obj_.gasLimit
                delete obj_.googleCode
                delete obj_.chain_protocol
                Api_.addNftcollectioninfo(obj_,fromValue.googleCode).then(res=>{
                    if(res.code === 0){
                        emit('successFun')
                        ElNotification({
                            message: $t('alert.succAdd'),
                            type: 'success',
                        })
                    }else{
                        ElNotification({
                            message: res.msg,
                            type: 'error',
                        })
                    }
                })
            }
        })
    }
    const beforeAvatarUpload = (e)=>{
        let file = new FormData()
        file.append("file",e.raw)
        Api_.getImgUrl(file).then(res=>{
            if(res.code === 0){
                fromValue.collectionImg = res.data.url
            }
        })
    }
    const setData1 = (obj)=>{
        if(obj){
            for(let index in fromValue){
                if(obj[index]){
                    fromValue[index] = obj[index]
                }
            }
        }else{
            for(let index in fromValue){
                fromValue[index] = ''
            }
            fromValue.createFrom = 2
            fromValue.chain_protocol = [fromValue.chain , fromValue.protocol]
        }
    }
    defineExpose({setData1,completeFun})
</script>
<style lang="scss" scoped>
    :deep(.avue-form__group .el-col) {
        display: flex;
    }
    .avatar-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
    .el-icon.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
    }
    :deep(.avatar-uploader .el-upload) {
        border: 1px dashed #ccc;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);
    }
    .lineWrite{
        line-height: 20px;
        font-size: 12px;
        margin-left: 16px;
        height: 178px;
        display: flex;
        align-items: flex-end;
    }
</style>