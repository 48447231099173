import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

// let validator = (rule,value,callback)=>{
//     if((!Number.isInteger(Number(value)))){
//         callback(new Error(t('otherWallatNft.relus1')))
//     }else if(Number(value) < 1){
//         callback(new Error(t('otherWallatNft.relus2')))
//     }else{
//         callback()
//     }
// }
const mainRules1 = {
    chain_protocol: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    contractAddress: [{ required: true, message: t('inputTit.xuanze'), trigger: 'blur' }],
    collectionName: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionSymbol: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    description: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
    collectionImg: [{ required: true, message: t('inputTit.shangchuan'), trigger: 'blur' }],
    googleCode: [{ required: true, message: t('inputTit.shuru'), trigger: 'blur' }],
}
export default{
    mainRules1
}